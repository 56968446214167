'use client';
import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import companyLogo from '../../assets/images/company-logo.svg';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleLinkClick = () => {
    setShowNavbar(false);
  };

  useEffect(() => {
    const scrollHandler = () => setScrollHeight(window.scrollY);
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <nav className={`navbar ${scrollHeight > 80 && `fixed`}`}>
      <Link to='/' className='logo'>
        <img src={companyLogo} alt='Sharpinvestr' />
      </Link>
      <div className={`links ${showNavbar && `active`}`}>
        <ul>
          <li onClick={handleLinkClick}>
            <Link to='/'>Home</Link>
          </li>
          <li onClick={handleLinkClick}>
            <Link to='/about-us'>About Us</Link>
          </li>
          <li onClick={handleLinkClick}>
            <Link to='/reviews'>Reviews</Link>
          </li>
          <li onClick={handleLinkClick}>
            <Link to='/regulations'>Regulation</Link>
          </li>
          <li onClick={handleLinkClick}>
            <Link to='/contact-us'>Contact Us</Link>
          </li>
          <div className='btns'>
            <Link
              onClick={handleLinkClick}
              to='/sign-up'
              className='btn transparent'
            >
              Sign up
            </Link>
            <Link to='/sign-in' onClick={handleLinkClick} className='btn green'>
              Sign in
            </Link>
          </div>
        </ul>
        <div className='close-btn' onClick={() => setShowNavbar(false)}>
          <FaTimes />
        </div>
      </div>
      <div className={`hamburger `} onClick={() => setShowNavbar(true)}>
        <FaBars />
      </div>
    </nav>
  );
};

export default Navbar;
