import React from 'react';
import btcLogo from '../../assets/images/btc-logo.png';
import ethLogo from '../../assets/images/eth-logo.png';
import usdtLogo from '../../assets/images/usdt-logo.png';
import mastercardLogo from '../../assets/images/mastercard-logo.png';
import { Link } from 'react-router-dom';
import companyLogo from '../../assets/images/company-logo.svg';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='payment-methods'>
        <div className='img'>
          <img src={btcLogo} alt='bitcoin payment' />
        </div>
        <div className='img'>
          <img src={ethLogo} alt='ethereum payment' />
        </div>
        <div className='img'>
          <img src={usdtLogo} alt='usdt payment' />
        </div>
        <div className='img'>
          <img src={mastercardLogo} alt='mastercard payment' />
        </div>
      </div>

      <div className='links'>
        <div className='item logo'>
          <img src={companyLogo} alt='Sharpinvestr' />
        </div>
        <div className='item'>
          <ul>
            <li>Home</li>
            <li>
              <Link to='/about-us'>About Us</Link>
            </li>
            <li>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <ul>
            <li>
              <Link to='/regulations'>Regulation</Link>
            </li>
            <li>
              <Link to='/terms-of-service'>Terms of Service</Link>
            </li>
            <li>
              <Link to='/reviews'>Reviews</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <ul>
            <li>
              <a href={`mailto:support@sharpinvestr.com`}>
                support@support@sharpinvestr.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='copyright'>
        <h3>Copyright &copy; 2020 Sharpinvestr</h3>
      </div>
    </footer>
  );
};

export default Footer;
