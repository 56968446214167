import React, { useEffect, useState } from 'react';
import { TransactionHistoryTable } from '../../../components/dashboard-components/tables/Tables';
import { useGlobalContext } from '../../../context/context';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { PageSpinner } from '../../../components/spinner/Spinner';

const TransactionHistory = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { baseURL } = useGlobalContext();

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/transaction-history`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content transaction-history-page'>
      <div className='title'>
        <h2>Transaction History</h2>
      </div>
      {loading ? (
        <PageSpinner />
      ) : !loading && data.length > 0 ? (
        <TransactionHistoryTable data={data} />
      ) : (
        <div className='no-data'>
          <h2>You currently do not have any transaction history</h2>
        </div>
      )}
    </main>
  );
};

export default TransactionHistory;
