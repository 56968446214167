import { useEffect } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import { Toaster } from 'react-hot-toast';

// PAGES WITHOUT AUTH
import {
  AboutUs,
  Home,
  ContactUs,
  Reviews,
  Regulations,
  Privacy,
  TermsOfService,
  SignUp,
  SignIn,
  ForgotPassword,
  VerifyEmail,
  CheckEmail,
  ChangePassword,
} from './pages/regular';

import {
  Deposit,
  KYC,
  Overview,
  Profile,
  ReferralProgram,
  TradingPlans,
  TransactionHistory,
  Withdraw,
} from './pages/dashboard';

import { PrivateRoute } from './pages/dashboard/PrivateRoute';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      {!pathname.includes(`/dashboard`) &&
        !pathname.includes(`/sign`) &&
        !pathname.includes(`/forgot-password`) &&
        !pathname.includes(`/verify-email`) &&
        !pathname.includes(`/check-email`) &&
        !pathname.includes(`/change-password`) && <Navbar />}
      <Routes>
        {/* regular pages */}
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/reviews' element={<Reviews />} />
        <Route path='/regulations' element={<Regulations />} />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/check-email' element={<CheckEmail />} />

        {/* dashboard pages */}
        <Route element={<PrivateRoute />}>
          <Route path='/dashboard/overview' element={<Overview />} />
          <Route path='/dashboard/profile' element={<Profile />} />
          <Route path='/dashboard/deposit' element={<Deposit />} />
          <Route path='/dashboard/withdrawal' element={<Withdraw />} />
          <Route path='/dashboard/trading-plans' element={<TradingPlans />} />
          <Route path='/dashboard/kyc' element={<KYC />} />
          <Route
            path='/dashboard/referral-program'
            element={<ReferralProgram />}
          />
          <Route
            path='/dashboard/transaction-history'
            element={<TransactionHistory />}
          />
        </Route>
      </Routes>
      {!pathname.includes(`/dashboard`) &&
        !pathname.includes(`/sign`) &&
        !pathname.includes(`/forgot-password`) &&
        !pathname.includes(`/verify-email`) &&
        !pathname.includes(`/check-email`) &&
        !pathname.includes(`/change-password`) && <Footer />}
      <Toaster position='top-center' />
    </>
  );
}

export default App;
