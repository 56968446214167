import React from 'react';
import Newsletter from '../../../components/newsletter/Newsletter';
import TradeWithUs from '../../../components/trade-with-us/TradeWithUs';
import PageHeader from '../../../components/page-header/PageHeader';
import locations from '../../../assets/images/about-us-map.png';

const AboutUs = () => {
  const aboutUsInfo = [
    {
      title: `Sharpinvestr: Your Gateway to Crypto Excellence`,
      info: `Welcome to Sharpinvestr, your trusted partner in the exhilarating world of cryptocurrency trading. We are a leading crypto trading company that thrives on innovation, integrity, and expertise. At Sharpinvestr, we empower individuals and businesses to navigate the complexities of the digital asset landscape, providing them with the tools, resources, and support needed to make informed trading decisions and unlock the potential of the blockchain revolution.`,
    },
    {
      title: `Our Mission`,
      info: `Our mission at Sharpinvestr is simple yet profound - to demystify cryptocurrency trading and make it accessible to everyone. We believe that the decentralized nature of cryptocurrencies has the power to reshape the global financial landscape. Therefore, we are committed to fostering a community of educated traders who can harness the immense possibilities that crypto assets offer.`,
    },
    {
      title: `Who We Are`,
      info: `Sharpinvestr is not just a company; we're a team of dedicated professionals with diverse backgrounds, ranging from finance and technology to cybersecurity and blockchain development. Our collective expertise enables us to provide you with a holistic perspective on the cryptocurrency market. With a finger on the pulse of the industry, we keep ourselves updated with the latest trends, regulatory changes, and technological advancements, ensuring that our clients are always a step ahead.`,
    },
    {
      title: `What We Offer`,
      list: [
        `Cutting-edge Trading Platform: Our user-friendly trading platform is designed to cater to both beginners and experienced traders. With intuitive interfaces, real-time market data, advanced charting tools, and seamless execution, we empower you to execute your trading strategies with confidence.`,
        `Educational Resources: We understand that knowledge is the key to success in the crypto space. Our extensive library of educational resources, including articles, videos, webinars, and tutorials, equips you with the know-how to make informed trading decisions and understand the intricacies of blockchain technology.`,
        `Personalized Support: Our dedicated customer support team is available to assist you 24/7. Whether you have a technical query, need trading guidance, or seek clarification on regulatory matters, we are here to provide prompt and reliable support.`,
        `Market Insights: Stay ahead of the curve with our in-depth market analyses and research reports. Our team of analysts distills complex market trends into actionable insights, enabling you to make data-driven trading choices.`,
        `Security First: We take the security of your assets seriously. Our platform employs industry-leading security measures, including multi-factor authentication, cold storage for the majority of funds, and regular security audits, to ensure the safety of your investments.`,
      ],
    },
    {
      title: `Our Values`,
      list: [
        `Integrity: Transparency and honesty form the bedrock of our operations. We are committed to building trust with our clients by providing accurate information and ethical practices.`,
        `Innovation: The crypto landscape is constantly evolving, and so are we. We embrace innovation and stay at the forefront of technological advancements to offer you the best trading experience.`,
        `Empowerment: Our goal is to empower you with the tools and knowledge needed to make confident trading decisions. Your success is our success.`,
      ],
    },
    {
      title: `Join Sharpinvestr Today`,
      info: `Whether you're an individual exploring the world of cryptocurrencies for the first time or a seasoned trader looking to diversify your portfolio, Sharpinvestr welcomes you with open arms. We invite you to embark on this exciting journey with us, as we navigate the dynamic realm of digital assets together. Your success is our priority, and we are committed to guiding you every step of the way.`,
    },
    {
      title: `Sharpinvestr - Where Excellence Meets Cryptocurrency Trading.`,
    },
  ];

  return (
    <>
      <main className='about-us-page'>
        <PageHeader pageTitle={`About Us `} />

        {/* ABOUT US INFORMATION */}
        <section className='about-info'>
          {aboutUsInfo.map((item, itemIndex) => {
            return (
              <div className='block' key={itemIndex}>
                <h3 className='title'>{item.title}</h3>
                <p>{item.info}</p>
                {item.list && (
                  <ul>
                    {item.list?.map((listItem, listItemIndex) => {
                      return <li key={listItemIndex}>{listItem}</li>;
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </section>

        {/* OUR DIFFERENT LOCATIONS AROUND THE WORLD */}
        <section className='locations'>
          <h2>Sharpinvestr around the world</h2>
          <div className='img'>
            <img
              src={locations}
              alt='our different locations around the world'
            />
          </div>
        </section>
        <TradeWithUs />
        <Newsletter />
      </main>
    </>
  );
};

export default AboutUs;
