import { FaLink } from 'react-icons/fa';
import { FaGift } from 'react-icons/fa6';
import { ImUserPlus } from 'react-icons/im';
import { CopyButton, ActionIcon, Tooltip } from '@mantine/core';
import { FaCopy, FaCheck } from 'react-icons/fa6';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/context';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

const ReferralProgram = () => {
  const [referralLink, setReferralLink] = useState(``);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const fetchReferralLink = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/users/user-info?fields=referralLink`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReferralLink(data.referralLink);
    } catch (error) {}
  };

  useEffect(() => {
    fetchReferralLink();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content referral-program-page'>
      <div className='title'>
        <h2>Referrals</h2>
        <p>Invite your friends and earn bonuses after they sign up</p>
      </div>

      <div className='steps-guide'>
        <div className='step'>
          <div className='icon'>
            <FaLink />
          </div>
          <h3>Send Invitation</h3>
          <p>Send your referral link to your friends and tell them about us</p>
        </div>
        <div className='step'>
          <div className='icon'>
            <ImUserPlus />
          </div>
          <h3>Registration</h3>
          <p>Let them register to our platform using your referral link</p>
        </div>
        <div className='step'>
          <div className='icon'>
            <FaGift />
          </div>
          <h3>Get Referral Bonus</h3>
          <p>Claim your referral bonus</p>
        </div>
      </div>

      <div className='share-the-link'>
        <h2>Share the referral link</h2>
        <p className='brief'>
          You can share your referall link by copying it and sending it to
          interested friends and family
        </p>

        <div className='link'>
          <p>{referralLink}</p>
          <CopyButton value={referralLink} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? 'Copied' : 'Copy'}
                withArrow
                position='right'
              >
                <ActionIcon
                  color={copied ? 'teal' : 'gray'}
                  variant='subtle'
                  onClick={copy}
                >
                  {copied ? <FaCheck /> : <FaCopy />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </div>
      </div>
    </main>
  );
};

export default ReferralProgram;
