import { Menu, rem } from '@mantine/core';
import { IconSettings, IconLogout, IconUser } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export const UserMenu = () => {
  const navigate = useNavigate();

  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <div className='circle'>
          <IconUser />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          onClick={() => navigate(`/dashboard/profile`)}
          leftSection={
            <IconSettings style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Account Settings
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            secureLocalStorage.removeItem(`user-info`);
            navigate(0);
          }}
          leftSection={
            <IconLogout style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
