import { AiFillHome } from 'react-icons/ai';
import { IoIosPeople } from 'react-icons/io';
import { RiFileList3Fill } from 'react-icons/ri';
import { FaHistory, FaLink, FaUserAlt } from 'react-icons/fa';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';

export const sidebarLinks = [
  {
    name: `Overview`,
    icon: <AiFillHome />,
    index: 1,
    link: `/dashboard/overview`,
  },
  {
    name: `Deposit`,
    icon: <GiPayMoney />,
    link: `/dashboard/deposit`,
  },
  {
    name: `Withdrawal`,
    icon: <GiReceiveMoney />,
    link: `/dashboard/withdrawal`,
  },
  {
    name: `Transaction History`,
    icon: <FaHistory />,
    link: `/dashboard/transaction-history`,
  },
  {
    name: `Profile`,
    icon: <FaUserAlt />,
    link: `/dashboard/profile`,
  },
  {
    name: `KYC`,
    icon: <IoIosPeople />,
    link: `/dashboard/kyc`,
  },
  {
    name: `Trading Plans`,
    icon: <RiFileList3Fill />,
    link: `/dashboard/trading-plans`,
  },
  // {
  //   name: `My Plans`,
  //   icon: <FaListAlt />,
  //   link: `/dashboard/my-plans`,
  // },
  {
    name: `Referral Program`,
    icon: <FaLink />,
    link: `/dashboard/referral-program`,
  },
];
