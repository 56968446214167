import Skeleton from 'react-loading-skeleton';

// SKELETON LOADER FOR TRENDING COINS
export const TrendingCoinsSkeleton = ({ noOfCard }) => {
  return (
    <>
      {Array(noOfCard)
        .fill(0)
        .map((_, index) => {
          return (
            <div className='trending-coin-skeleton' key={index}>
              <Skeleton height={100} />
            </div>
          );
        })}
    </>
  );
};
