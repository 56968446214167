import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaArrowTrendUp } from 'react-icons/fa6';
import axios from 'axios';
import { TrendingCoinsSkeleton } from '../skeleton-loaders/SkeletonLoaders';

export const CryptoTable = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`
      );
      setData(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className='crypto-table'>
      <div className='header'>
        <p>#</p>
        <p>Name</p>
        <p>Price</p>
        <p className='tab-up'>All Time High</p>
        <p className='tab-up'>ATH Date</p>
      </div>

      {data.length > 0 && (
        <div className='items'>
          {data?.slice(0, 50).map((coin, coinIndex) => {
            const { current_price, image, name, ath, ath_date } = coin;
            return (
              <div className='item' key={coinIndex}>
                <p>{coinIndex + 1}</p>
                <div className='crypto'>
                  <div className='img'>
                    <img src={image} alt={name} />
                  </div>
                  <p>{name}</p>
                </div>
                <p>${current_price?.toLocaleString(`en-US`)}</p>
                <p className='tab-up'>${ath.toLocaleString(`en-US`)}</p>
                <p className='tab-up'>{moment(ath_date).format(`LL`)}</p>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export const TrendingCoins = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `https://api.coingecko.com/api/v3/search/trending`
      );
      setData(
        data.coins.sort(
          (a, b) =>
            b.item.data.price_change_percentage_24h.usd -
            a.item.data.price_change_percentage_24h.usd
        )
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className='trending-coins'>
      <h2 className='title'>Trending Assets</h2>
      <div className='grid'>
        {data.length < 1 ? (
          <TrendingCoinsSkeleton noOfCard={3} />
        ) : (
          <>
            {data?.slice(0, 3).map((coin, coinIndex) => {
              const { data, large: image, name, symbol } = coin.item;
              const { price, price_change_percentage_24h, sparkline } = data;

              return (
                <div className='item' key={coinIndex}>
                  <div className='icon-name'>
                    <div className='icon'>
                      <img src={image} alt={name} />
                    </div>
                    <h4>{name}</h4>
                    <p>{symbol}</p>
                  </div>
                  <div className='chart-price'>
                    <div className='chart'>
                      <img
                        src={sparkline}
                        width={100}
                        height={100}
                        alt={`${name} chart`}
                      />
                    </div>
                    <div className='price'>
                      <p>{price?.toString().slice(0, 8)}</p>
                      <span
                        className={
                          price_change_percentage_24h?.usd < 0 ? `fall` : ``
                        }
                      >
                        <FaArrowTrendUp />{' '}
                        {price_change_percentage_24h?.usd
                          .toString()
                          .slice(0, 5)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </section>
  );
};
