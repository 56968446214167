import React from 'react';

const Newsletter = () => {
  return (
    <section className='newsletter'>
      <div className='container'>
        <h3>Don&apos;t miss out, Be the first to know</h3>
        <p>
          Get notified about the details of the Forex Market, as well as other
          important development update.
        </p>

        <div className='email-input'>
          <input type='email' />
          <button className='linear'>Subscribe</button>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
