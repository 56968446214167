import { Modal } from '@mantine/core';
import { GoCheckCircleFill } from 'react-icons/go';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import secureLocalStorage from 'react-secure-storage';

export const NotificationModal = ({ opened, closeFunc, notification }) => {
  const { title, content } = notification;
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const updateNotificationStatus = async () => {
    try {
      await axios.patch(
        `${baseURL}/users/user-info`,
        { seen: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={closeFunc}
      centered
      size='lg'
      className='notification-modal'
      overlayProps={{
        backgroundOpacity: 0.55,
      }}
    >
      <h2 className='title'>{title}</h2>

      <div
        className='content'
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>

      <div className='btn'>
        <button
          className='linear'
          onClick={() => {
            closeFunc();
            if (!notification.persistent && !notification.seen) {
              updateNotificationStatus();
            }
          }}
        >
          Okay
        </button>
      </div>
    </Modal>
  );
};

export const DepositSuccessModal = ({ opened, closeFunc, amount }) => {
  return (
    <Modal
      opened={opened}
      onClose={closeFunc}
      centered
      size='lg'
      className='transaction-successful-modal'
      overlayProps={{
        backgroundOpacity: 0.55,
      }}
    >
      <div className='icon'>
        <GoCheckCircleFill />
      </div>
      <h1>Deposit Request Completed</h1>
      <p>
        You have made a deposit request of ${amount?.toLocaleString(`en-US`)}{' '}
        and it is now awaiting approval
      </p>

      <div className='btn'>
        <Link to='/dashboard/transaction-history'>
          <button className='linear'>Transaction History</button>
        </Link>
      </div>
    </Modal>
  );
};

export const WithdrawalSuccessModal = ({ opened, closeFunc, amount }) => {
  return (
    <Modal
      opened={opened}
      onClose={closeFunc}
      centered
      size='lg'
      className='transaction-successful-modal'
      overlayProps={{
        backgroundOpacity: 0.55,
      }}
    >
      <div className='icon'>
        <GoCheckCircleFill />
      </div>
      <h1>Withdrawal Request Completed</h1>
      <p>
        You have made a withdrawal request of ${amount?.toLocaleString(`en-US`)}{' '}
        and it is now awaiting approval
      </p>

      <div className='btn'>
        <Link to='/dashboard/transaction-history'>
          <button className='linear'>Transaction History</button>
        </Link>
      </div>
    </Modal>
  );
};
