import React from 'react';
import Newsletter from '../../../components/newsletter/Newsletter';
import TradeWithUs from '../../../components/trade-with-us/TradeWithUs';
import PageHeader from '../../../components/page-header/PageHeader';
import CustomersReviews from '../../../components/customers-reviews/CustomersReviews.jsx';

const Reviews = () => {
  return (
    <>
      <main className='reviews-page'>
        <PageHeader pageTitle={`Reviews and Testimonies `} />

        <div className='header'>
          <h2 className='title'>Embracing Healthy Competition</h2>
          <p>
            At Sharpinvestr, we value healthy competition and prioritize
            transparency. As a reflection of this commitment, we abstain from
            inserting our own opinions. Instead, our focus is on uncovering
            areas where improvement is possible. While we diligently sought
            feedback to understand any concerns, it&apos;s unfortunate that in
            many instances, we received limited specific feedback.
          </p>
          <p>
            To gain genuine insights into the user experience on our platform,
            we introduced a trade bonus in exchange for feedback, irrespective
            of its nature, be it positive or negative. The following compilation
            presents authentic perspectives and reviews from real users of the
            Sharpinvestr platform.
          </p>
        </div>

        <div className='reviews'>
          <CustomersReviews />
        </div>

        <TradeWithUs />
        <Newsletter />
      </main>
    </>
  );
};

export default Reviews;
