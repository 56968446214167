import React from 'react';
import Newsletter from '../../..//components/newsletter/Newsletter';
import TradeWithUs from '../../..//components/trade-with-us/TradeWithUs';
import PageHeader from '../../..//components/page-header/PageHeader';

const Privacy = () => {
  const privacyInfo = [
    {
      title: ``,
      info: `Welcome to the Sharpinvestr Automated Crypto Trading Platform Privacy Policy. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our automated crypto trading platform and related services. By accessing or using Sharpinvestr, you consent to the practices described in this Privacy Policy.`,
    },
    {
      title: `1. Information We Collect`,
      list: [
        `Personal Information: We may collect personal information, such as your name, email address, payment details, and other relevant data when you create an account or use our services.`,
        `Usage Data: We collect information about your interactions with the platform, including IP addresses, device information, operating system, and browser type.`,
        `Trading Data: We collect trading data generated by your use of our automated trading services, including trading history, strategies, and performance.`,
      ],
    },
    {
      title: `2. Use of Information`,
      list: [
        `Service Provision: We use your information to provide and improve our services, enhance user experience, and communicate with you regarding your account and transactions.`,
        `AAlgorithmic Improvements: Collected data may be used to enhance our automated trading algorithms for better performance and accuracy.`,
        ` Legal Compliance: We may use your information to comply with legal obligations, enforce our terms of service, and protect the rights, safety, and security of Sharpinvestr and its users.`,
      ],
    },
    {
      title: `3. Data Sharing`,
      list: [
        `Third Parties: We may share your information with third-party service providers for purposes such as payment processing, data analysis, and customer support.`,
        `Aggregated Data: We may share aggregated and anonymized data for marketing, research, and analytical purposes.`,
        `Legal Requirements: We may disclose your information if required by law or if we believe that such disclosure is necessary to protect our rights, safety, or the safety of others.`,
      ],
    },
    {
      title: `4. Data Security`,
      info: `We implement technical and organizational measures to safeguard your personal information, including encryption and secure data storage. However, please note that no method of data transmission over the internet is completely secure.`,
    },
    {
      title: `5. Your Choices`,
      list: [
        `Account Information: You can review and update your account information by logging into your Sharpinvestr account.`,
        `Communication Preferences: You can manage your communication preferences by adjusting your account settings or opting out of promotional emails.`,
      ],
    },
    {
      title: `6. Children's Privacy`,
      info: `Sharpinvestr is not intended for individuals under the age of 18. We do not knowingly collect personal information from children.`,
    },
    {
      title: `7. Changes to Privacy Policy`,
      info: `We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a prominent notice on our website or by directly sending you a notification.`,
    },
    {
      title: `8. Contact Us`,
      info: `If you have any questions or concerns about our Privacy Policy, please contact us at [contact email].`,
    },
    {
      title: `By using Sharpinvestr, you acknowledge that you have read, understood, and agreed to the practices described in this Privacy Policy. Your privacy is important to us, and we are dedicated to maintaining the security and confidentiality of your personal information.`,
    },
  ];

  return (
    <>
      <main className='privacy-policy-page'>
        <PageHeader pageTitle={`Privacy Policy `} />

        {/* ABOUT US INFORMATION */}
        <section className='privacy-info'>
          <h2 className='title'>Last Updated: September 10, 2023</h2>
          {privacyInfo.map((item, itemIndex) => {
            return (
              <div className='block' key={itemIndex}>
                {item.title && <h3 className='title'>{item?.title}</h3>}
                <p>{item.info}</p>
                {item.list && (
                  <ol>
                    {item.list.map((listItem, listItemIndex) => {
                      return <li key={listItemIndex}>{listItem}</li>;
                    })}
                  </ol>
                )}
              </div>
            );
          })}
        </section>

        <TradeWithUs />
        <Newsletter />
      </main>
    </>
  );
};

export default Privacy;
