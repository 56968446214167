import React from 'react';
import { sidebarLinks } from './data';
import companyLogo from '../../../assets/images/company-logo.svg';
import { Link } from 'react-router-dom';

const Sidebar = ({ currentPage }) => {
  return (
    <aside className='desktop-sidebar'>
      <div className='logo'>
        <img src={companyLogo} width={300} height={300} alt='Sharpinvestr' />
      </div>
      <ul className='links'>
        {sidebarLinks.map((link, linkIndex) => {
          const { icon, name, link: pageLink } = link;
          return (
            <li
              className={
                currentPage.toLocaleLowerCase() === pageLink ? `active` : ``
              }
              key={linkIndex}
            >
              <Link to={pageLink}>
                {icon} {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
