import { BsArrowDownLeft, BsArrowUpRight } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import moment from 'moment';
import { useState } from 'react';

export const TransactionHistoryTable = ({ data }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [activeTrx, setActiveTrx] = useState({});

  return (
    <>
      <div className='table'>
        <div className='header'>
          <p className='details'>Details</p>
          <p className='amount'>Amount</p>
          <p className='tab-up status'>Status</p>
          <p className='tab-up options'></p>
        </div>

        <div className='transaction-details'>
          {data.map((item) => {
            const { _id, amount, createdAt, trxType, status } = item;

            return (
              <div
                className='item'
                onClick={() => {
                  setActiveTrx(data.filter((trx) => trx._id === _id)[0]);
                  open();
                }}
                key={_id}
              >
                <div className='details'>
                  <div className={`icon ${status}`}>
                    {trxType === `deposit` ? (
                      <BsArrowDownLeft />
                    ) : (
                      <BsArrowUpRight />
                    )}
                  </div>
                  <div className='info'>
                    <p>
                      {trxType === `deposit`
                        ? `Deposited Funds`
                        : trxType === `withdrawal`
                        ? `Withdrew Funds`
                        : `Deposited Funds`}
                    </p>
                    <span>{moment(createdAt).format(`lll`)}</span>
                  </div>
                </div>
                <div className='amount'>
                  <p>
                    {trxType === `deposit`
                      ? `+ ${amount?.toLocaleString(`en-US`)}`
                      : `- ${amount?.toLocaleString(`en-US`)}`}{' '}
                    USD
                  </p>
                </div>
                <div className='tab-up status'>
                  <p className={status}>{status}</p>
                </div>
                <div className='tab-up options'>
                  <div className='icon' onClick={open}>
                    <FaEye />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        centered
        size='lg'
        className='transaction-details-modal'
        overlayProps={{
          backgroundOpacity: 0.55,
        }}
      >
        <div className='title'>
          <h2>Transaction</h2>
        </div>

        <div className='type-amount'>
          <div className={`icon ${activeTrx?.status}`}>
            {activeTrx?.trxType === `deposit` ? (
              <BsArrowDownLeft />
            ) : (
              <BsArrowUpRight />
            )}
          </div>
          <div className='info'>
            <h2>
              {activeTrx?.trxType === `deposit`
                ? `+ ${activeTrx?.amount?.toLocaleString(`en-US`)}`
                : `- ${activeTrx?.amount?.toLocaleString(`en-US`)}`}{' '}
              USD
            </h2>
            <span>{moment(activeTrx?.createdAt).format(`lll`)}</span>
          </div>
        </div>

        <div className='txr-info'>
          <h2 className='title'>Transaction Info</h2>
          <div className='items'>
            <div className='item'>
              <h4>Reference ID</h4>
              <p>{activeTrx?.ref}</p>
            </div>
            <div className='item'>
              <h4>Amount</h4>
              <p>{activeTrx?.amount?.toLocaleString(`en-US`)} USD</p>
            </div>
            <div className='item'>
              <h4>Transaction Fee</h4>
              <p>0 USD</p>
            </div>
            <div className='item'>
              <h4>Transaction Type</h4>
              <p className='trx-type'>{activeTrx?.trxType}</p>
            </div>
            <div className='item'>
              <h4>
                {activeTrx.trxType === `deposit`
                  ? `Payment To`
                  : activeTrx.trxType === `withdrawal`
                  ? `Withdrawal Address`
                  : null}
              </h4>
              <p>
                {activeTrx.trxType === `deposit`
                  ? activeTrx.paymentTo
                  : activeTrx.trxType === `withdrawal`
                  ? activeTrx.address
                  : null}
              </p>
            </div>
            <div className='item'>
              <h4>Asset Transfered</h4>
              <p className='asset'>{activeTrx?.type}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
