import AccountSettingsTabs from '../../../components/dashboard-components/account-settings-tabs/AccountSettingsTabs';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import secureLocalStorage from 'react-secure-storage';

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/users/user-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content profile-page'>
      <div className='title'>
        <h4>Account Setting</h4>
        <h2>My Profile</h2>
      </div>

      {!loading && data && (
        <AccountSettingsTabs userData={data} triggerFetch={setLoading} />
      )}
    </main>
  );
};

export default Profile;
