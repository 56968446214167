import { WithdrawFundsForm } from '../../../components/forms/Forms';
import React from 'react';

const Withdraw = () => {
  return (
    <main className='content withdrawal-page'>
      <div className='title'>
        <h3>Withdrawal</h3>
        <h2>What would you like to Withdraw today?</h2>
      </div>
      <WithdrawFundsForm />
    </main>
  );
};

export default Withdraw;
