import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = ({ pageTitle, briefInfo }) => {
  return (
    <article className='page-header'>
      <div className='title'>
        <h1>{pageTitle}</h1>
      </div>
      {briefInfo && (
        <div className='info'>
          <p>{briefInfo}</p>
        </div>
      )}
      <Link to='/sign-up' className='btn green'>
        Start Trading
      </Link>
    </article>
  );
};

export default PageHeader;
